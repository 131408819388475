import React from "react";
import { NavLink } from 'react-router-dom'
import himg from '../../images/slider/right-img.png'
import { Link } from 'react-scroll'

const Hero =() => {
    return (
        <section className="tp-hero-section-1">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 col-lg-12 text-center">
                        <div className="tp-hero-section-text">
                            <div className="tp-hero-title">
                                <h2>Pratik Ramoliya</h2>
                            </div>
                            <div className="tp-hero-sub">
                                <p>Your home match-maker</p>
                            </div>
                            {/* <div className="btns">
                                <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-95} className="theme-btn">Contact Us</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="right-vec">
                <div className="right-img">
                    <img src={himg} alt=""/>
                </div>
            </div> */}
            <div className="social-link">
                <ul>
                    <li><a href="https://www.facebook.com/pratik.ramoliya.5?mibextid=ZbWKwL" target='_blank'>Facebook</a></li>
                    <li><a href="https://wa.me/15199952625" target='_blank'>Whatsapp</a></li>
                    <li><a href="https://www.instagram.com/realtor_pratik_ramoliya?igsh=M3dlN2pkNGRnMW0=" target='_blank'>Instagram</a></li>
                    <li><a href="tel:+15199952625">Call</a></li>
                </ul>
            </div>
            {/* <div className="visible-text">
                <h1>Developer</h1>
            </div> */}
        </section>
    )
}

export default Hero;