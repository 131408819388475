import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo-white.png'
import Realty from '../../images/realty.png'

const Footer = (props) => {
    return (
        <div className="tp-site-footer text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-image">
                            <Link className="logo" to="/"><img src={Logo} alt="" /></Link>
                            <Link className="logo" to="/"><img src={Realty} alt="" /></Link>
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="link-widget">
                            <ul>
                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                <li><Link to="/"><i className="ti-vimeo-alt"></i></Link></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-12 mt-4">
                        <div className="copyright">
                            <p><a href="tel:+15199952625">+1(519)995-2625</a>  </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="copyright">
                            <p>   <a href="mailto:pratik@lcplatinumrealty.com" target='_blank'>pratik@lcplatinumrealty.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;