import React from 'react'
// import ContactForm from '../ContactFrom/ContactForm'
import Facebook from '../../images/facebook.png';
import Instagram from '../../images/instagram.png';
import Whatsapp from '../../images/whatsapp.png';
import Call from '../../images/phone.png';
import { Face } from '@material-ui/icons';

const ContactArea = (props) => {
    return (
        <section className="tp-contact-form-area section-padding">
            <div className="container">
                <div className="tp-contact-form-wrap">
                    <div class="contact-image">
                    <video autoPlay muted loop id="video-bg" width={350}>
          <source src="https://realtorpratikramoliya.com/contact.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
                    </div>
                    <div className="tp-section-title">
                        <h2>GET IN TOUCH</h2>
                        <p>I would be happy to help you navigate the buying and selling process.</p>
                        <div class="contact-icons">
                            <div class="call">
                                <a href="tel:+15199952625"><img src={Call} alt="Ring me" /></a>
                                <div><a href="tel:+15199952625">+1(519)995-2625</a></div>
                            </div>
                            <div class="call">
                                <a href="https://wa.me/phone=15199952625" target='_blank'><img src={Whatsapp} alt="Ring me" /></a>
                                <div><a href="https://wa.me/15199952625" target='_blank'>+1(519)995-2625</a></div>
                            </div>
                            <div class="call">
                                <a href="https://www.facebook.com/pratik.ramoliya.5?mibextid=ZbWKwL" target='_blank'><img src={Facebook} alt="Ring me" /></a>
                                <div><a href="https://www.facebook.com/pratik.ramoliya.5?mibextid=ZbWKwL" target='_blank'>Pratik Ramoliya</a></div>
                            </div>
                            <div class="call">
                                <a href="https://www.instagram.com/realtor_pratik_ramoliya?igsh=M3dlN2pkNGRnMW0=" target='_blank'><img src={Instagram} alt="Ring me" /></a>
                                <div><a href="https://www.instagram.com/realtor_pratik_ramoliya?igsh=M3dlN2pkNGRnMW0=" target='_blank'>realtor_pratik_ramoliya</a></div>
                            </div>
                            
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* <div className="visible-rotate-text">
                <h1>Contat Me</h1>
            </div> */}
        </section>
    )
}

export default ContactArea;