import React from 'react'

const MarqueeWords = (props) => {
    return (
        <div className="digital-marque-sec">
            <div className="container">
                <div className="digital-marque">
                    <div className="marquee">
                        <div className="track">
                            <div className="content">
                                <h1>REALTOR, HOME MATCH MAKER, EVENT PLANNER, NEGOTIATOR, OPEN HOUSE HOST, MENTOR, HOME ADVISOR, INSPECTION COORDINATOR, OFFER FACILITATOR, PROPERTY MANAGER, PORTFOLIO MANAGER, EDUCATOR, BIDDING STRATEGIEST, PRICING STRATEGIEST, BUSINESS MANAGER, RISK ASSESSOR, CLIENT ADVOCATE, TOUR GUIDE, MARKETING MANAGER, TOUR GUIDE, CONTENT CREATOR, DATA RESEARCH ANALYST, NEIGHBOURHOOD EXPERT
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarqueeWords;