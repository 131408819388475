import React from 'react'
import aImg from '../../images/aboutme.png'

const About = (props) => {
    return (

        <section className="tf-about-section section-padding">
            <div className="container">
                <div className="tf-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-img">
                                <img src={aImg} alt="" />
                                {/* <div className="tf-about-img-text">
                                    <div className="tf-about-icon">
                                        <h3>8+</h3>
                                        <span>Years Exprience</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-text">
                                <small>about Me</small>
                                <h2>Discover your perfect agent right where you stand.</h2>
                                {/* <h5>I have 8+ years of experiences in Software Development for give you better services.</h5> */}
                                <p>With a passion for real estate and a dedication to excellence, I provide personalized service to clients buying, selling, leasing, or investing in properties. Working with integrity and professionalism, I ensure each client receives the attention they deserve. Staying ahead in the market, I leverage current trends and conditions to offer innovative solutions. Integrity, transparency, and open communication are central to my approach.</p>
                                <p>Whether you're a first-time homebuyer or a seasoned investor, I guide you with honesty and expertise. I aim to exceed expectations and achieve outstanding results. Building lasting relationships, I prioritize your needs and preferences. With transparent communication, I ensure you're informed at every step. Trust me to navigate the complexities of real estate with integrity and professionalism.</p>

                                {/* <div className="tf-funfact">
                                    <div className="tf-funfact-item">
                                        <h3><span>500</span>+</h3>
                                        <p>Projects Completed</p>
                                    </div>
                                    <div className="tf-funfact-item">
                                        <h3><span>52</span>+</h3>
                                        <p>Awards Win</p>
                                    </div>
                                    <div className="tf-funfact-item">
                                        <h3><span>2</span>M+</h3>
                                        <p>Happy clients</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="visible-rotate-text">
                <h1>About Me</h1>
            </div> */}
        </section>
    )
}

export default About;